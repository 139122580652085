/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
// DO NOT UPDATE MANUALLY.
// This file is automatically generated by scripts/update-version-module.js
// before publishing.
export const npmVersion = '0.15.1';
export const serviceWorkerHash = 'f56081d9';
